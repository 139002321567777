var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-card-info mt-4"},[_c('span',{directives:[{name:"b-toggle",rawName:"v-b-toggle.PriceQuotationInfoData",modifiers:{"PriceQuotationInfoData":true}}],staticClass:"collapse-title"},[_vm._v(" "+_vm._s(_vm.$t("StoreItems.data"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})]),_c('b-collapse',{attrs:{"id":"PriceQuotationInfoData"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"user-img-container col-lg-4"},[_c('img',{staticClass:"user-img",attrs:{"src":_vm.fullPathFileFromServer(
              _vm.priceQuotation.storeItemInfoData
                ? _vm.priceQuotation.storeItemInfoData.storeItemImagePath
                : '',
              _vm.priceQuotation.defaultImg
            ),"onerror":("this.src='" + (_vm.priceQuotation.defaultImg) + "'")}})]),_c('div',{staticClass:"col-lg-4 col-md-6"},[_c('DataLabelGroup',{attrs:{"value":_vm.priceQuotation.storeItemInfoData
              ? _vm.priceQuotation.storeItemInfoData.fullCode
              : '',"title":_vm.$t('StoreItems.code'),"imgName":'code.svg'}}),_c('DataLabelGroup',{attrs:{"value":_vm.priceQuotation.storeItemInfoData
              ? _vm.priceQuotation.storeItemInfoData.storeItemNameCurrent
              : '',"title":_vm.$t('StoreItems.name'),"imgName":'StoreItems.svg'}}),_c('DataLabelGroup',{attrs:{"value":_vm.priceQuotation.storeItemQuantity,"title":_vm.$t('StoreItems.quantity'),"imgName":'number.svg'}})],1),_c('div',{staticClass:"col-lg-4 col-md-6"},[_c('DataLabelGroup',{attrs:{"value":_vm.priceQuotation.bestPriceTotalItemsAmountWithCurrency,"title":_vm.$t('StoreItems.price.bestTotalPrice'),"imgName":'money.svg'}}),_c('DataLabelGroup',{attrs:{"value":_vm.priceQuotation.bestPriceStoreItemAmountWithCurrency,"title":_vm.$t('StoreItems.price.bestOnePrice'),"imgName":'money.svg'}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }